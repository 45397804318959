import React, {useContext} from 'react';
import './header.css';
import { useNavigate } from 'react-router-dom';
import { agentSignupUrl, agentLoginUrl, privacyPolicyUrl, pricingUrl, agentDashboard, aboutUrl, addCandidate, homeUrl } from "../../utils/navigateUrls";
import FeaturesSvg from './features_svg';
import useRouteState from '../../hooks/useRouteState';
import Logo from './logo';

import LoginContext from '../../Auth/LoginContext';
import { trackEvent, eventCategories } from '../../constants/analytics';



const Header = () => {

    const getClassForRoute = useRouteState();
    const { isLoggedIn, setIsLoggedIn, agentDetails } = useContext(LoginContext);

    const navigate = useNavigate();

    const agentSignUp = () => {
        const url = agentSignupUrl();
        trackEvent(eventCategories.userAccount, 'Agent_action', 'signup'); 
        navigate(url);
    }
    const agentLogin = () => {
        const url = agentLoginUrl(); 
        trackEvent(eventCategories.userAccount, 'Agent_action', 'login'); 
        navigate(url);
    }

    const privacyPolicy = (e) => {
        e.preventDefault();
        const url = privacyPolicyUrl(); 
        navigate(url);
    }

    const pricingPage = (e) => {
        e.preventDefault();
        const url = pricingUrl(); 
        navigate(url)
    }

    const aboutPage = (e) => {
        e.preventDefault();
        const url = aboutUrl(); 
        navigate(url)
    }

    const navigateToDashboard = (e) => {
        e.preventDefault();
        const url = agentDashboard({agent_id: agentDetails?.id});
        trackEvent(eventCategories.userAccount, 'profile_icon_click', 'agent_dashboard'); 
        navigate(url);
    }

    const navigateToAddCandidate = (e) => {
        e.preventDefault();
        trackEvent(eventCategories.userAccount, 'profile_icon_click', 'add_candidate'); 
        const url = addCandidate({agent_id: agentDetails?.id})
        navigate(url);
    }

    const logOut = (e) => {
        e.preventDefault();
        setIsLoggedIn(false);
        trackEvent(eventCategories.userAccount, 'Agent_action', 'logout'); 
        setTimeout(() => {
            navigate(homeUrl())
        }, 1000);
    }

    const LoginToggleView = () => {
        return (
            <>
                <div>
                    {isLoggedIn ? (
                            <div className="dropdown text-end">
                                <a href="#" className="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="/user.png" alt="mdo" width="32" height="32" className="rounded-circle"/>
                                </a>
                                <ul className="dropdown-menu text-small">
                                    {/* <li><a className="dropdown-item" href="#">New project...</a></li> */}
                                    {/* <li><a className="dropdown-item" href="#">Settings</a></li> */}
                                    <li><a className="dropdown-item" href="#" onClick={navigateToDashboard}>Dashboard</a></li>
                                    <li><a className="dropdown-item" href="#" onClick={navigateToAddCandidate}>Add Candidate</a></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><a className="dropdown-item" href="#" onClick={logOut}>Sign out</a></li>
                                </ul>
                            </div>
                        ) : (
                            <div className="text-end">
                                <button type="button" className="btn btn-outline-light me-2" onClick={agentLogin}>Agent Login</button>
                                <button type="button" className="btn btn-warning" onClick={agentSignUp}>Agent Sign-up</button>
                            </div>
                        )}
                </div>
                <div style={{marginLeft:'0.8em'}}>
                    { isLoggedIn && agentDetails ? (
                        <>{agentDetails.name}</>
                    ): null }
                </div>
               
            </>
        )
    }
    
    return (
        <main>
           
            <FeaturesSvg/>
            <header className="p-3 text-bg-dark">
                <div className="container">
                    <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                        <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
                            {/* <svg className="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap"><use xlinkHref="#bootstrap"></use></svg> */}
                            {/* <img src="download.png" alt="SVG Image" width="40" height="32"/> */}
                            {/* Career Eazy */}
                            <Logo/>
                            {/* <button type="button" className="btn btn-primary btn-lg px-4 me-md-2">Primary</button> */}
                        </a>

                        <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                            <li><a href="/" className={getClassForRoute('/')}>Home</a></li>
                            {/* <li><a href="#" onClick={pricingPage} className={getClassForRoute('/pricing')}>Pricing</a></li> */}
                            <li><a href="#" onClick={privacyPolicy} className={getClassForRoute('/privacyPolicy')}>Privacy Policy</a></li>

                            <li><a href="#" onClick={aboutPage} className={getClassForRoute('/about')}>About us</a></li>
                        </ul>

                        <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
                            {/* Add your form elements here */}
                        </form>
                        <LoginToggleView/>
                    </div>
                </div>
            </header>
                
        </main>
    );
}

export default Header;
