import React,{ useEffect, useState } from "react";
import { Button } from "@mui/material";
import { createOrderApi } from "../api/paymentApi"
import { getAmountInPaise } from "../utils/paymentUtil";

const apiKey = process.env.REACT_APP_PAYMENT_API_KEY;

const Checkout = ({ paymentHandler, paymentPayload, paymentInitiated }) => {
    const [orderResponse, setOrderResponse] = useState({});
    const [paymentResponse, setPaymentResponse] = useState({});
    console.log(`payment payload ${JSON.stringify(paymentPayload)}`)
    const initCheckout = async () => {
        var options = {
            "key": apiKey, // Enter the Key ID generated from the Dashboard
            "amount": await getAmountInPaise(paymentPayload.amount), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": paymentPayload.currency || "INR",
            "name": paymentPayload.name,
            "description": paymentPayload.description,
            "image": paymentPayload.image,
            "order_id": orderResponse?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": function (response){
                console.log(`response.razorpay_payment_id ${response.razorpay_payment_id}`);
                console.log(`response.razorpay_order_id ${response.razorpay_order_id}`);
                console.log(`response.razorpay_signature ${response.razorpay_signature}`);
                setPaymentResponse(response);
                paymentHandler(response);
            },
            "prefill": paymentPayload.prefill,
            "notes": paymentPayload.notes,
            "theme": paymentPayload.theme
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response){
                console.log(`payment failed response ${JSON.stringify(response)}`);
                setPaymentResponse(response);
                paymentHandler(response);
        });
        paymentInitiated();
        rzp1.open();
    }

    useEffect(() => {
        if(orderResponse?.id){
            initCheckout()
        }
    }, [orderResponse])

    const createOrder = async () => {
        try {
                const payload =  {
                    "paymentDetails":{
                        "amount": await getAmountInPaise(paymentPayload.amount),
                        "currency": paymentPayload.currency || "INR",
                        "receipt": paymentPayload.receipt || "qwsaq1",
                        // "partial_payment": true,
                        // "first_payment_min_amount": 230
                    }
                }
                const response = await createOrderApi(payload);
                console.log(`order response ${JSON.stringify(response)}`);
                setOrderResponse(response?.data);
                return response;
        } catch (error) {
            console.log(`order creation error ${error}`);
            return error;
        }
    }

    return (
        <div>
            <Button onClick={ ()=> createOrder()}>
                Checkout
            </Button>
        </div>
    )
}
export default Checkout;