import React from 'react';
const HeroDarkColor = () => {
    return (
        <>
             <div className="bg-dark text-secondary px-4 py-5 text-center">
                <div className="py-5">
                <h1 className="display-5 fw-bold text-white">Forge Your Career Path with Career Eazy</h1>
                <div className="col-lg-6 mx-auto">
                    <p className="fs-5 mb-4">
                        At Career Eazy, we bridge the gap between agencies and top-tier talent. Our platform offers agencies a seamless experience for posting jobs, managing applications, and finding the perfect match for their team. For job seekers, we provide access to a wide array of career opportunities, personalized job alerts, and the tools needed to showcase their skills and experience to potential employers. Join us today and take the next step towards your professional goals.
                    </p>
                    <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                    {/* <button type="button" className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold">Custom button</button>
                    <button type="button" className="btn btn-outline-light btn-lg px-4">Secondary</button> */}
                    </div>
                </div>
                </div>
            </div>
        </>
       
    )
}
export default HeroDarkColor;