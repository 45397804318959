import React, { createContext, useState } from 'react';

const LoginContext = createContext({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  agentDetails: false,
  setAgentDetails: () => {}
});

export default LoginContext;
