import React, {useContext,useState} from "react";
import WhatsAppLogin from "./whatsappLogin";
import Heading from "./Heading";
import Header from "./landing_page/header";
import LoginContext from '../Auth/LoginContext';
import { getAgentDetailsByPhoneNumber } from "../api/agentApi";
import { useNavigate } from "react-router-dom";
import { agentDashboard } from "../utils/navigateUrls";
import AutoHideAlert from './autoHideAlert';



const AgentLogin = () => {
    const { isLoggedIn, setIsLoggedIn, agentDetails, setAgentDetails } = useContext(LoginContext);
    const navigate = useNavigate();
    const [agentLogin, setAgentLogin] = useState(false);

    const onWhatsappSubmit = async (otpResponse) => {
        console.log("whatsapp submit" + otpResponse);
        // localStorage.setItem('isLoggedIn', otpResponse?.isSuccess);
        setIsLoggedIn(otpResponse?.isSuccess);
        if(otpResponse?.isSuccess){
            const agentResponse = await getAgentDetailsByPhoneNumber({
                phone_number: otpResponse?.phone_number
            });
            const agentDetails = agentResponse?.data;
            console.log(`agentDetails: ${JSON.stringify(agentDetails)}`)
            setAgentDetails(agentDetails);
            setAgentLogin(true);

            setTimeout(() => {
                navigate(agentDashboard({agent_id: agentDetails?.id}))
            }, 3000);

           
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAgentLogin(false);
      };

    return (
        <>
            <Header/>
            <Heading title="Agent Login" variant="h4"/>
            <WhatsAppLogin onSubmit={onWhatsappSubmit}></WhatsAppLogin>
            <AutoHideAlert
            message="Agent logged in successfully, redirecting to agent dashboard"
            open={agentLogin}
            handleClose={handleClose}
            timeout={3000}
        />
        </>
    )
}
export default AgentLogin;