import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './component/landing_page/styles.css';
import LoginContext from './Auth/LoginContext';
import { router } from './route/route';
import { RouterProvider, useNavigate} from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [agentDetails, setAgentDetails] = useState({});

  useEffect(() => {
    const storedUser = localStorage.getItem('isLoggedIn');
    const agentDetails = JSON.parse(localStorage.getItem('agentDetails'));
    console.log('storedUser:', storedUser);
    setIsLoggedIn(storedUser === 'true');
    setAgentDetails(agentDetails);
   
  }, []); // Ensure that the dependency array is empty

  const handleLogin = (loginSuccess) => {
    console.log(`login response ${loginSuccess}`)
    // Login logic
    if(loginSuccess){
      localStorage.setItem('isLoggedIn', 'true');
      setIsLoggedIn(true);
    }else{
      localStorage.setItem('isLoggedIn', 'false');
      setIsLoggedIn(false);
    }
  };

  const handleAgentDetails = (agentDetails) => {
    console.log(`agentDetails: ${JSON.stringify(agentDetails)}`);
    setAgentDetails(agentDetails);
    localStorage.setItem('agentDetails', JSON.stringify(agentDetails));
  }
  

  return (
    <React.StrictMode>
      <LoginContext.Provider value={{ isLoggedIn, setIsLoggedIn: handleLogin, agentDetails, setAgentDetails: handleAgentDetails }}>
        <RouterProvider router={router} /> {/* Wrap App with provider */}
      </LoginContext.Provider>
    </React.StrictMode>
  );
}

export default App;
