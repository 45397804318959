import React from 'react';
import { Typography } from "@mui/material";
export const Heading = ({title, variant}) => {
    return (
        <>
            <Typography className="mt-4" variant={variant} gutterBottom align="center">
                    {title}
            </Typography>
        </>
    )
}
export default Heading;