import AWS from 'aws-sdk';
const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS,
    region: 'ap-south-1'
});
export const downloadFromS3 = async (key, bucket) => {
    try {
      const params = {
        Bucket: bucket,
        Key: key
      };
      const data = await s3.getObject(params).promise();
      // Convert the data to a blob
      const blob = new Blob([data.Body], { type: data.ContentType });
      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);
      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', key); // Set the filename for the download
      // Append the link to the body and trigger the download
      document.body.appendChild(link);
      link.click();
      // Clean up by removing the link and revoking the URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading object:', error);
    }
  };