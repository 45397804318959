import React, {useState,useContext} from 'react';
import WhatsAppSignup from './whatsappSignup';
import AutoHideAlert from './autoHideAlert';
import { useNavigate } from "react-router-dom";
import { agentDashboard } from "../utils/navigateUrls";
import LoginContext from '../Auth/LoginContext';

const AgentSignup = () => {
    const [agentSignedUp, setAgentSignedUp] = useState(false);
    const { isLoggedIn, setIsLoggedIn, agentDetails, setAgentDetails } = useContext(LoginContext);
    const [agent, setAgent] = useState({});
    const navigate = useNavigate();
    const onSignup = (response) => {
        const agent = response?.data;
        setAgent(agent);
        setAgentSignedUp(true);
        setIsLoggedIn(true);
        setAgentDetails(agent);
        setTimeout(() => {
            navigate(agentDashboard({agent_id: agent?.id}));
        }, 3000);

    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAgentSignedUp(false);
      };
    return (
        <>
         <WhatsAppSignup onSignup={onSignup} isSignup={true}></WhatsAppSignup>
        <AutoHideAlert
            message="Agent signed up successfully, redirecting to agent dashboard"
            open={agentSignedUp}
            handleClose={handleClose}
            timeout={3000}
        />
        </>
    )
}
export default AgentSignup;