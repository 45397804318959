import React from 'react';
import Snackbar from '@mui/material/Snackbar';
const AutoHideAlert = ({message, open, handleClose, timeout}) => {
    return (
        <>
                <Snackbar
                    sx={{ height: "100%"}}
                    open={open}
                    autoHideDuration={timeout}
                    onClose={handleClose}
                    message={message}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        </>
    )
}
export default AutoHideAlert;