import React, { useState } from 'react';
import { TextField, Button, Grid } from '@mui/material';
import AWS from 'aws-sdk';
import AutoHideAlert from './autoHideAlert';

const S3Uploader = ({ uploadText, onFileChange, onFileUpload, bucket, s3FileKey }) => {
  const [file, setFile] = useState(null);
  const [fileUploadProgress, setFileUploadProgress] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileName = file.name;
    if(!fileName.endsWith('.pdf'))
    {
        window.alert('Only pdf files are supported. Please upload pdf file only. \nUse online tools like https://smallpdf.com/word-to-pdf to convert to pdf');
        return;
    }
    console.log(`tar file ${fileName}`)
    setFile(event.target.files[0]);
    onFileChange(event.target.files[0]);
  };

  const handleUpload = (e) => {
    setFileUploadProgress(true);
    // Configure AWS with your access key and secret key
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS
    });

    // Create S3 instance
    const s3 = new AWS.S3();

    // Define parameters for the upload
    const params = {
      Bucket: bucket,
      Key: s3FileKey,
      Body: file
    //   ACL: 'public-read' // Optionally set the access control list
    };

    // Upload file to S3
    s3.putObject(params, (err, data) => {
      if (err) {
        console.error('Error uploading file:', err);
        setFileUploadProgress(false);
        onFileUpload({
          status: "failed"
        })
        
      } else {
        console.log('File uploaded successfully:', data);
        setFileUploadProgress(false);
        onFileUpload({
          status:"success",
          data
        })

      }
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFileUploadProgress(false);
  };

  return (
    <>
     <TextField
          // variant="outlined"
          type="file"
          onChange={handleFileChange}
          // InputLabelProps={{ shrink: true }}
          fullWidth
      />
      <Button 
        variant="contained" 
        onClick={handleUpload} 
        disabled={!file}
        sx={{ marginTop: '16px' }} 
      >
        {uploadText}
      </Button>
      { fileUploadProgress ? 
            <AutoHideAlert 
                message="Uploading file..."
                open={fileUploadProgress}
                handleClose={handleClose}
            />
                : null
      }
    </>
  );
};

export default S3Uploader;
