// Define event categories
export const eventCategories = {
    candidateActions: 'Candidate Actions',
    userAccount: 'User Account',
    dashboardInteraction: 'Dashboard Interaction',
    contactForm: 'Contact Form'
};

export const trackEvent = (category, action, label, parameters) => {
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label,
      ...parameters // Spread parameters object if available
    });
}