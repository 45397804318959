import { createBrowserRouter } from "react-router-dom";
import WhatsAppLogin from "../component/whatsappLogin";
import WhatsAppSignup from "../component/whatsappSignup";
import AddCandidate from "../component/addCandidate";
import AgentDashboard from "../component/agentDashboard";
import AgentSignup from "../component/agentSignup";
import Container from "../component/landing_page/container";
import PrivacyPolicy from "../component/landing_page/privacyPolicy";
import Pricing from "../component/landing_page/pricing";
import Header from "../component/landing_page/header";
import Heading from "../component/Heading";
import AgentLogin from "../component/agentLogin";
import About from "../component/landing_page/about";
import Checkout from "../component/checkout";


const paymentPayload = {
    "amount": 100,
    "currency": "INR",
    "name": "Opencompetition corp",
    "description": "Opencompetition checkout",
    "image": "https://opencompetitionsoftwares.com/logo192.png",
    "prefill": {
        "name":"Prem Kumar",
        "email":"opencompetitionsoftwares@gmail.com",
        "contact": "9043742544"
    },
    "notes":{
        "address": "Open Competition Software Head Office"
    },
    "theme":{
        "color": "#3399cc"
    },
    "receipt": "qwsaq1"
}

const paymentHandler = (data) => {

}

const paymentInitiated = () => {

}

export const router = createBrowserRouter([
{
    path: '/',
    element: <Container></Container>
},
{
    path: '/addAgent',
    element: <><Header/><Heading title="Agent SignUp" variant="h4"/><AgentSignup/></>
},
{
    path: '/agentLogin',
    element: <AgentLogin/>
},
{
    path: 'agent/:agentId/addCandidate',
    element:<><Header/><Heading title="Add Candidate" variant="h4"/><AddCandidate/></>
},
{
    path: 'agent/:agentId/dashboard',
    element:  <><Header/><Heading title="Welcome to Agent Dashboard" variant="h4"/><AgentDashboard/></>
},
{
    path:'/privacyPolicy',
    element: <><Header/><PrivacyPolicy/></>
},
{
    path:'/about',
    element:<><Header/><About/></>
},
{
    path:'/checkout',
    element:<><Checkout paymentHandler={paymentHandler} paymentPayload={paymentPayload} paymentInitiated={paymentInitiated}/></>
},

// Todo: Enable after payment integration
// {
//     path:'/pricing',
//     element: <><Header/><Pricing/></>
// },
{
    path: '*',
    element: <Container></Container>
}
// {
//     path: '/review',
//     element: <>
//         <div id="review-carousel" data-customer-sid="CS00005" data-theme="dark"></div>
//         <div id="postReview" data-customer-sid="CS00001" data-widget-name="review_post"></div>
//         <div id="rating" data-customer-sid="CS00005" data-widget-name="company_rating"></div>
//     </>
// }
]);