import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_API;

export const addAgent = async (req) => {
    return await axios.post(`${BASE_URL}/agents/addAgent`, { ...req });
};

export const initiateOtp = async (req) => {
    return await axios.post(`${BASE_URL}/agents/initiateOtp`, { ...req });
};

export const verifyOtp = async (req) => {
    return await axios.post(`${BASE_URL}/agents/verifyOtp`, { ...req });
};

export const addCandidate = async (req) => {
    const { agent_id } = req;
    return await axios.post(`${BASE_URL}/agents/${agent_id}/addCandidate`, { ...req });
}

export const getCandidates = async ({ agent_id }) => {
    return await axios.get(`${BASE_URL}/agents/${agent_id}/candidates`);
}

export const searchCandidatesByAgent = async(req) => {
    const { agent_id } = req;
    return await axios.post(`${BASE_URL}/${agent_id}/searchCandidates`, { ...req });
}

export const getAgentDetailsByPhoneNumber = async(req) => {
    return await axios.post(`${BASE_URL}/agents/getAgentDetails`, { ...req });
}