import React from 'react'
import {  useLocation } from 'react-router-dom';


const useRouteState = () => {
    const location = useLocation();
    const currentRoute = location.pathname;
    const getClassForRoute = (match) => {
        if (currentRoute === match) {
          return "nav-link px-2 text-secondary";
        } else {
          return "nav-link px-2 text-secondary text-white";
        }
    };
    return getClassForRoute;
}
export default useRouteState;