import React from 'react';
import Contact from '../contact';

const About = () => {
  return (
    <>
      <div className="container" style={{marginTop:'3em'}}>
        <h1>About Us</h1>
        <p>Welcome to our job agent website!</p>
        <p>At CareerEazy, we are dedicated to revolutionizing the recruitment process for job agents and candidates alike. Our platform provides a comprehensive set of tools to streamline the hiring journey.</p>
        <h2>Our Mission</h2>
        <p>Our mission is to empower job agents with the resources they need to efficiently connect talented candidates with rewarding career opportunities. We strive to foster a seamless and transparent recruitment experience.</p>
        <h2>Key Features</h2>
        <p>Our platform offers a range of features designed to enhance the recruitment process:</p>
        <ul>
          <li><strong>Signup and Login:</strong> Secure account creation and login functionality for job agents.</li>
          <li><strong>Resume Upload:</strong> Easily upload and manage candidates' resumes.</li>
          <li><strong>Advanced Search:</strong> Efficiently search candidates' resumes by skill role, location, experience, and more.</li>
          <li><strong>Communication:</strong> Seamlessly communicate with candidates via integrated WhatsApp messaging.</li>
          <li><strong>Resume Download:</strong> Conveniently download and export resumes for further review.</li>
        </ul>
        {/* <h2>Contact Us</h2>
        <p>If you have any questions, feedback, or suggestions, we'd love to hear from you! Our dedicated support team is here to assist you.</p>
        <p>Get in touch with us via email at careereazy2024@gmail.com or reach out to us via email careereazy2024@gmail.com</p>
        <p>Thank you for choosing CareerEazy for your recruitment needs!</p> */}
      </div>
      <Contact/>
      <div className="container" style={{marginTop:'1em'}}>
        <p>If you have any questions, feedback, or suggestions, we'd love to hear from you! Our dedicated support team is here to assist you.</p>
        <p>Get in touch with us via email at careereazy2024@gmail.com or reach out to us via email careereazy2024@gmail.com</p>
        <p>Thank you for choosing CareerEazy for your recruitment needs!</p>
      </div>
    </>
    
  );
}

export default About;
