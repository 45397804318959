export const addCandidate = ({agent_id}) => {
    return `/agent/${agent_id}/addCandidate`;
}
export const agentDashboard = ({agent_id}) => {
    return `/agent/${agent_id}/dashboard`;
}

export const agentSignupUrl= () => {
    return `/addAgent`;
}

export const agentLoginUrl = () => {
    return `/agentLogin`;
}

export const privacyPolicyUrl = () => {
    return `/privacyPolicy`;
}

export const pricingUrl = () => {
    return `/pricing`;
}
export const aboutUrl = () => {
    return `/about`;
}

export const homeUrl = () => {
    return `/`;
}