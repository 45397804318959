import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container" style={{marginTop:'3em'}}>
      <div className="row">
        <div className="col">
          <h1>Privacy Policy</h1>
          <p>This Privacy Policy describes how CareerEazy ("we," "us," or "our") collects, uses, and shares personal information of users of our website, including job agents and candidates. By using our website, you agree to the terms of this Privacy Policy.</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>Information We Collect</h2>
          <p>We collect personal information directly from users when they use our website, including when they sign up as job agents or candidates. The information we collect may include:</p>
          <ul>
            <li>Job Agent Information: Name, phone number, email address, and any other information provided during the registration process.</li>
            <li>Candidate Information: Name, phone number, resume, and any other information provided by candidates when uploading their resumes.</li>
            <li>Usage Information: We may collect information about how users interact with our website, such as the pages visited and actions taken.</li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>How We Use Information</h2>
          <p>We may use the information we collect for various purposes, including to:</p>
          <ul>
            <li>Provide and improve our services.</li>
            <li>Communicate with users, including sending notifications and updates.</li>
            <li>Enable job agents to search for candidates based on skill roles.</li>
            <li>Send WhatsApp messages to candidates based on search results.</li>
            <li>Comply with legal obligations.</li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>Data Security</h2>
          <p>We take appropriate measures to protect the security of personal information. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>Data Sharing</h2>
          <p>We may share personal information with third-party service providers who assist us in operating our website and providing services. We may also share information in response to legal requests or to protect our rights.</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>User Responsibilities</h2>
          <p>Users are prohibited from misusing the platform for any unlawful or unethical purposes, including but not limited to:</p>
          <ul>
            <li>Sharing false or misleading information.</li>
            <li>Using the platform for fraudulent activities.</li>
            <li>Violating the privacy rights of others.</li>
            <li>Engaging in harassment or discrimination.</li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>Your Choices</h2>
          <p>Users may update their account information and preferences at any time. They may also request the deletion of their account and associated data.</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>Children's Privacy</h2>
          <p>Our website is not directed to children under the age of 13, and we do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will delete it.</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>Changes to this Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. We will notify users of any material changes by posting the new Privacy Policy on this page.</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>Contact Us</h2>
          <p>If you have any questions or concerns about this Privacy Policy, please contact us at careereazy2024@gmail.com</p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
