import React from 'react';
import './features.css';
// import FeaturesSvg from './features_svg';

const Features = () => {
    return (
        <>
            <main>
                <div className="container px-4 py-5" id="featured-3">
                    <h2 className="pb-2 border-bottom">Main Features</h2>
                    <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                        <div className="feature col">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                                <svg className="bi" width="1em" height="1em"><use xlinkHref="#search"/></svg>
                            </div>
                            <h3 className="fs-2 text-body-emphasis">Find Candidates</h3>
                            <p>
                                Say goodbye to endless scrolling through irrelevant profiles. With our efficient search engine, you can quickly pinpoint candidates who possess the qualifications and attributes you're looking for, saving valuable time and resources in the recruitment process.
                            </p>
                            {/* <a href="www.example.com" className="icon-link">
                                Call to action
                                <svg className="bi"><use xlinkHref="#chevron-right"/></svg>
                            </a> */}
                        </div>
                        <div className="feature col">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                                <svg className="bi" width="1em" height="1em"><use xlinkHref="#message"/></svg>
                            </div>
                            <h3 className="fs-2 text-body-emphasis">Message Candidates</h3>
                            <p>
                                With just a few clicks, you can send personalized messages directly to candidates' WhatsApp numbers, keeping them informed about new job postings and exciting career opportunities. Say goodbye to delays and missed connections – our instant messaging feature ensures that your messages reach candidates in real-time, wherever they are.
                            </p>
                            {/* <a href="www.example.com" className="icon-link">
                                Call to action
                                <svg className="bi"><use xlinkHref="#chevron-right"/></svg>
                            </a> */}
                        </div>
                        <div className="feature col">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                                <svg className="bi" width="1em" height="1em"><use xlinkHref="#download"/></svg>
                            </div>
                            <h3 className="fs-2 text-body-emphasis">Download Resumes</h3>
                            <p>
                                Simplify your recruitment workflow and eliminate manual data entry tasks with our automated download and export features. Spend less time on administrative tasks and more time focusing on what matters most – finding the perfect candidate for your clients and advancing your recruitment goals.
                            </p>
                            {/* <a href="www.example.com" className="icon-link">
                                Call to action
                                <svg className="bi"><use xlinkHref="#chevron-right"/></svg>
                            </a> */}
                        </div>
                    </div>
                </div>
            </main>
        </>
        
    );
}

export default Features;
