import React from "react";
import './heroes.css';

const HeroSection = () => {
    return (
        <>
            <main>
                <div className="px-4 pt-5 my-5 text-center border-bottom">
                    <h1 className="display-4 fw-bold text-body-emphasis">Connecting Talents Globally</h1>
                    <div className="col-lg-6 mx-auto">
                    <p className="lead mb-4">
                        Welcome to Career Easy, your premier destination for connecting job agencies with top-tier talent. Whether you're a seasoned recruiter or a job seeker ready to take the next step in your career, we provide the tools and resources to streamline the hiring process and facilitate meaningful connections.

                    </p>
                    {/* <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
                        <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3">Primary button</button>
                        <button type="button" className="btn btn-outline-secondary btn-lg px-4">Secondary</button>
                    </div> */}
                    </div>
                    <div className="overflow-hidden" style={{ maxHeight: '30vh' }}>
                    <div className="container px-5">
                        <img src="bootstrap-docs.jpeg" className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example image" width="700" height="500" loading="lazy"/>
                    </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default HeroSection;
