import React from 'react';

const FooterSection = () => {
    return (
        // <footer className="container py-5">
        //     <div className="row">
        //         <div className="col-12 col-md">
        //             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="d-block mb-2" role="img" viewBox="0 0 24 24">
        //                 <title>Product</title>
        //                 <circle cx="12" cy="12" r="10"/>
        //                 <path d="M14.31 8l5.74 9.94M9.69 8h11.48M7.38 12l5.74-9.94M9.69 16L3.95 6.06M14.31 16H2.83m13.79-4l-5.74 9.94"/>
        //             </svg>
        //             <small className="d-block mb-3 text-body-secondary">&copy; 2017–2024</small>
        //         </div>
        //         <div className="col-6 col-md">
        //             <h5>Features</h5>
        //             <ul className="list-unstyled text-small">
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Cool stuff</a></li>
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Random feature</a></li>
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Team feature</a></li>
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Stuff for developers</a></li>
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Another one</a></li>
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Last time</a></li>
        //             </ul>
        //         </div>
        //         <div className="col-6 col-md">
        //             <h5>Resources</h5>
        //             <ul className="list-unstyled text-small">
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Resource name</a></li>
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Resource</a></li>
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Another resource</a></li>
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Final resource</a></li>
        //             </ul>
        //         </div>
        //         <div className="col-6 col-md">
        //             <h5>Resources</h5>
        //             <ul className="list-unstyled text-small">
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Business</a></li>
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Education</a></li>
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Government</a></li>
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Gaming</a></li>
        //             </ul>
        //         </div>
        //         <div className="col-6 col-md">
        //             <h5>About</h5>
        //             <ul className="list-unstyled text-small">
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Team</a></li>
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Locations</a></li>
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Privacy</a></li>
        //                 <li><a className="link-secondary text-decoration-none" href="www.example.com">Terms</a></li>
        //             </ul>
        //         </div>
        //     </div>
        // </footer>
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
            <p className="col-md-4 mb-0 text-body-secondary">&copy; 2024 CareerEazy, Inc</p>
    
            {/* <a href="/" className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                <Logo/>
            </a>
     */}
            <ul className="nav col-md-4 justify-content-end">
            <li className="nav-item"><a href="/" className="nav-link px-2 text-body-secondary">Home</a></li>
            {/* <li className="nav-item"><a href="#" className="nav-link px-2 text-body-secondary">Features</a></li> */}
            {/* <li className="nav-item"><a href="/pricing" className="nav-link px-2 text-body-secondary">Pricing</a></li> */}
            <li className="nav-item"><a href="/privacyPolicy" className="nav-link px-2 text-body-secondary">PrivacyPolicy</a></li>
            {/* <li className="nav-item"><a href="#" className="nav-link px-2 text-body-secondary">About</a></li> */}
            </ul>
        </footer>
  
    );
}

export default FooterSection;
