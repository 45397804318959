import React, { useState } from 'react';
import { Container, Grid, Typography, TextField, Button, Snackbar } from '@mui/material';
import { sendEmail } from '../api/emailApi';
import AutoHideAlert from './autoHideAlert';
import { eventCategories, trackEvent } from '../constants/analytics';
import { useLocation } from 'react-router-dom';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [validated, setValidated] = useState(false);
  const [emailSent, setEmailSent] = useState(false); 
  const location = useLocation();
  const currentPath = location.pathname; 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      // Call your function with form data
      sendMessage(formData);
    } else {
      setValidated(true);
    }
  };

  const sendMessage = (data) => {
    // Your function logic here
    console.log("Name:", data.name);
    console.log("Email:", data.email);
    console.log("Message:", data.message);
    const payload = {
        name: data?.name,
        email: data?.email,
        message: data?.message
    }
    setEmailSent(true);
    const sendEmailMessage = async () => {
      const emailResponse = await sendEmail(payload);
      console.log(`email response ${emailResponse}`);
      setEmailSent(false);
    }
    
    trackEvent(eventCategories.contactForm, "submit", currentPath)
    sendEmailMessage();
    // Optionally, you can use AJAX to send data to server here
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setEmailSent(false);
  };


  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom align="center">Contact Us</Typography>
          <form onSubmit={handleSubmit} noValidate={validated ? true : undefined}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Your Name"
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
              required
              error={!formData.name && validated}
              helperText={!formData.name && validated && "Please enter your name."}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              id="email"
              name="email"
              type="email"
              label="Your Email"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
              required
              error={!formData.email && validated}
              helperText={!formData.email && validated && "Please enter a valid email address."}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              id="message"
              name="message"
              label="Message"
              multiline
              rows={5}
              variant="outlined"
              value={formData.message}
              onChange={handleChange}
              required
              error={!formData.message && validated}
              helperText={!formData.message && validated && "Please enter your message."}
              sx={{ mb: 2 }}
            />
            <Button type="submit" variant="contained" color="primary">Send Message</Button>
          </form>
        </Grid>
      </Grid>
      {
        emailSent ? <AutoHideAlert 
        open={emailSent} 
        message={"Email sent successfully."} 
        handleClose={handleClose} 
        timeout={1000}/> : null
      }
    </Container>
  );
};

export default Contact;
