import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_API;

export const createOrderApi = async (payload) => {
    return await axios.post(`${BASE_URL}/payment/createOrder`, payload);
}

export const updatePaymentResults = async(payload) => {
    return await axios.post(`${BASE_URL}/payment/paymentResultUpdate`, payload);
}

export const getPaymentResults = async(payload) => {
    return await axios.post(`${BASE_URL}/payment/paymentStatus`, payload);
}