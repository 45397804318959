import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
const Typeahead = ({ label, onSelected, onChange, autoCompleteList }) => {
    return (
        <Autocomplete
            onChange={(e,v) => onSelected(v)}
            disablePortal
            id="combo-box-demo"
            options={autoCompleteList}
            style={{width: "100%"}}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label={label} onChange={onChange}/>}
            fullWidth
            variant="outlined"
        />
    )
}
export default Typeahead;