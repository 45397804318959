import {ERROR_MESSAGES} from "../constants/errorConstants";
export const getValidatedWhatsAppNumberMessages = (phoneNumber) => {
    if(phoneNumber.length==0){
        return ERROR_MESSAGES.EMPTY_WHATSAPP_NUMBER;
    } else if(phoneNumber.length>10){
        return ERROR_MESSAGES.WHATSAPPNO_GREATER_10;
    } else if(!isPureNumber(phoneNumber)){
        return ERROR_MESSAGES.NUMBERS_ARE_ALLOWED;
    }
    else{
       return "";
    } 
}
export const isPureNumber = (value) => {
    return /^\d+$/.test(value);
}
const isTextAllowedForSearchRule = (str) => {
    const ruleCondition = !isStringFullOfIntegers(str) && !isStopWord(str) && str.length > 1;
    // console.log(`isTextAllowedForSearchRule str ${str} ruleCondition ${ruleCondition}`)
    return ruleCondition;
}
const isStringFullOfIntegers = (str) => {
    return /^\d+$/.test(str);
}

const removeLeadingTrailingSpecialChar = (str) => {
    let regex = /^[^\w\d\s]+|[^\w\d\s]+$/g;
    return str.replace(regex, '');
}


// todo: more rules would be applied here for search suggestions
// add stopword rule

const isStopWord = (str) => {
    const stopwords = [
        'i', 'me', 'my', 'myself', 'we', 'our', 'ours', 'ourselves', 'you', 'your', 'yours',
        'yourself', 'yourselves', 'he', 'him', 'his', 'himself', 'she', 'her', 'hers',
        'herself', 'it', 'its', 'itself', 'they', 'them', 'their', 'theirs', 'themselves',
        'what', 'which', 'who', 'whom', 'this', 'that', 'these', 'those', 'am', 'is', 'are',
        'was', 'were', 'be', 'been', 'being', 'have', 'has', 'had', 'having', 'do', 'does',
        'did', 'doing', 'a', 'an', 'the', 'and', 'but', 'if', 'or', 'because', 'as', 'until',
        'while', 'of', 'at', 'by', 'for', 'with', 'about', 'against', 'between', 'into',
        'through', 'during', 'before', 'after', 'above', 'below', 'to', 'from', 'up', 'down',
        'in', 'out', 'on', 'off', 'over', 'under', 'again', 'further', 'then', 'once', 'here',
        'there', 'when', 'where', 'why', 'how', 'all', 'any', 'both', 'each', 'few', 'more',
        'most', 'other', 'some', 'such', 'no', 'nor', 'not', 'only', 'own', 'same', 'so',
        'than', 'too', 'very', 's', 't', 'can', 'will', 'just', 'don', 'should', 'now'
    ];
    return stopwords.includes(str);
}

const applySearchTextRules = (str) => {
    const lowerCaseWord = str.toLowerCase();
    return removeLeadingTrailingSpecialChar(lowerCaseWord);
}

export const prepareDataForAutoCompletion = (arrayOfObjects = []) => {
    const hashMap = arrayOfObjects.reduce((acc, obj) => {
        const words = obj.resume_text.split(' '); // Split the label into individual words
        words.forEach(word => {
            const processedWord = applySearchTextRules(word);
            if(isTextAllowedForSearchRule(processedWord)){
                if (!acc[processedWord]) {
                    acc[processedWord] = [obj.id];
                } else if (!acc[processedWord].includes(obj.id)) {
                    acc[processedWord].push(obj.id);
                }
            }
            // words.forEach(word => acc[word]?.push(obj.id) || (acc[word] = [obj.id]));
        });
        return acc;
    }, {});
    
    const arrayOfArrayObjects = Object.entries(hashMap).map(([label, ids]) => {
        return { label, ids };
    });
    
    // console.log(arrayOfArrayObjects);
    return arrayOfArrayObjects;
}