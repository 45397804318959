import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { downloadFromS3 } from '../utils/s3Util';
import { sendTemplateMessage } from '../api/whatsappApi';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { eventCategories, trackEvent } from '../constants/analytics';
import LoginContext from '../Auth/LoginContext';


const sendMessage = (row) => {
    console.log('Edit button clicked for row ID:', row);
};

const downloadResume = (row) => {
  const key = row?.metadata?.key ?? null;
  const bucket = row?.metadata?.bucket ?? null;
  if(!key || !bucket){
    console.log(`Either key or bucket value is invalid for candidate id: ${row?.id}`)
    return false;
  }
  trackEvent(eventCategories.dashboardInteraction, 'Download Resume', 'Candidate Profile Downloaded');
  downloadFromS3(key, bucket);
}


export default function CandidateSearchList({rows}) {

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const { isLoggedIn, setIsLoggedIn, agentDetails } = useContext(LoginContext);

  const maskPhoneNumber = (number) => {
    return  "********" + number.slice(-2);
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'masked_phone_number',
      headerName: 'Phone Number',
      width: 150,
      editable: true,
      renderCell: (params) => (
        <p>{maskPhoneNumber(params.row.phone_number)}</p>
      )
    },
    {
      field: 'metadata',
      headerName: 'Resume',
      // type: 'number',
      width: 110,
      editable: true,
      renderCell: (params) => (
        <IconButton aria-label="edit" onClick={() => downloadResume(params.row)}>
         <CloudDownloadIcon />
        </IconButton>
      )
    },
    {
      field: 'sendJobMessage',
      headerName: 'Send Message',
      // description: 'This column has a value getter and is not sortable.',
      // sortable: false,
      width: 160,
      // valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
      renderCell: (params) => (
        <IconButton aria-label="edit" onClick={() => handleOpen(params.row)}>
          <WhatsAppIcon />
        </IconButton>
      )
    },
  ];

  const handleOpen = (candidate) => {
    setOpen(true);
    setSelectedCandidate(candidate);
    trackEvent(eventCategories.dashboardInteraction, 'send_message', 'opening whatsapp message box');
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCandidate(null);
    trackEvent(eventCategories.dashboardInteraction, 'send_message', 'closing whatsapp message box');
  };

  const sendMessage = async () => {
    console.log('Sending message:', message, selectedCandidate);
    if(message && message.length>0){
      trackEvent(eventCategories.dashboardInteraction, 'send_message', 'send whatsapp message to candidates');
      let payload = {
        "phone_number": selectedCandidate.phone_number,
        "template_name": "user_message",
        "template_body_params": [agentDetails?.name, message],
        "template_image_headers":[]
      }
      // console.log(agentDetails);
      await sendTemplateMessage(payload);
    }
    handleClose();
  };

  const handleSelectionChange = (selection) => {
    setSelectedRows(selection); 
    console.log(selection);
  }
  
  const handleExport = () => {
    
    // filter needed information 
    const data = rows.map(row => {
      return {
        Id: row.id,
        CandidateName: row.name,
        WhatsappNumber: row.phone_number
      }
    })
    const filteredRows = data.filter(row => selectedRows.includes(row.Id));
    const headers = Object.keys(filteredRows[0]);
    const csvString = [
      headers.join(","),
      ...filteredRows.map(obj => Object.values(obj).join(","))
    ].join("\n");

    // Create a Blob object with the CSV content
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

    // Create a hidden anchor element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "data.csv"; // Set desired filename

    // Simulate a click to trigger download
    link.click();
    trackEvent(eventCategories.dashboardInteraction, 'Export csv', 'Candidate Profile Downloaded', {total_export_count: filteredRows.length});
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          // initialState={{
          //   pagination: {
          //     paginationModel: {
          //       pageSize: 10,
          //     },
          //   },
          // }}
          // pageSizeOptions={[10]}
          checkboxSelection
          // disableRowSelectionOnClick
          onRowSelectionModelChange={handleSelectionChange}
          // selectionModel={selectionModel}
        />
      </Box>
      {selectedRows.length > 0 && (
          <Button 
            style={{ marginTop: '1em' }} 
            variant="contained" 
            color="primary"
            onClick={handleExport}
          >
            Export
          </Button>
       )}
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="message"
            label="Message"
            type="text"
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={sendMessage} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    
  );
}
