import React from 'react';
import './heroes.css';
import HeroDarkColor from './hero_dark_color';


const HeroLeft = () => {
    return (
        <>
            <div className="container col-xxl-8 px-4 py-5 border-top">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div className="col-10 col-sm-8 col-lg-6">
                        <img src="bootstrap-themes.jpeg" className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
                    </div>
                    <div className="col-lg-6">
                        <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">Stay Organized and Informed</h1>
                        <p className="lead">
                            Keep your recruitment process organized and stay informed about candidate progress with our download and export features. Whether you're tracking candidate interactions, analyzing recruitment metrics, or preparing for client meetings, our tools empower you to stay one step ahead at every stage of the recruitment journey.
                        </p>
                        {/* <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            <button type="button" className="btn btn-primary btn-lg px-4 me-md-2">Primary</button>
                            <button type="button" className="btn btn-outline-secondary btn-lg px-4">Default</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <HeroDarkColor/>
            <div className="container my-5">
                <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
                <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
                    <h1 className="display-4 fw-bold lh-1 text-body-emphasis">Career Eazy: Your Gateway to Success</h1>
                    <p className="lead">
                        Welcome to CareerConnect, the ultimate destination for agencies and job seekers alike. With our intuitive platform, agencies can effortlessly post jobs, manage applications, and connect with top talent from around the globe. For job seekers, CareerConnect offers personalized job recommendations, career advice, and the tools to showcase your skills to potential employers. Join us today and unlock a world of opportunities!
                    </p>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
                    {/* <button type="button" className="btn btn-primary btn-lg px-4 me-md-2 fw-bold">Primary</button>
                    <button type="button" className="btn btn-outline-secondary btn-lg px-4">Default</button> */}
                    </div>
                </div>
                <div className="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
                    <img className="rounded-lg-3" src="bootstrap-docs.jpeg" alt="" width="720"/>
                </div>
                </div>
            </div>
        </>
    )
}
export default HeroLeft;