import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid } from '@mui/material';
import { getValidatedWhatsAppNumberMessages, isPureNumber } from '../utils/utils';
import {initiateOtp, verifyOtp} from "../api/agentApi"
import Typography from '@mui/material/Typography';
import { eventCategories, trackEvent } from '../constants/analytics';

const WhatsAppLogin = ({ propsDisableSubmit, onSubmit, isSignup }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [otpButtonDisabled, setOtpButtonDisabled] = useState(false);
    const reSendOtpIntervalInMs = 90 * 1000;
    const [sendOtpText, setSendOtpText] = useState('Send OTP');
    const [otpTimer, setOtpTimer] = useState(reSendOtpIntervalInMs/1000);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [phoneNumberErrorText, setPhoneNumberErrorText] = useState('');
    const otpLength = 5;
    let otpSentTime = 0;
    
    const onPhoneNumberBlur = () => {
        if(phoneNumber.length==0 || !isPureNumber(phoneNumber)){
            setPhoneNumberError(phoneNumber.length==0);
        }
        setPhoneNumberErrorText(getValidatedWhatsAppNumberMessages(phoneNumber));  
    }

    useEffect(() => {
        if (otpSent && otpTimer > 0) {
            const timerInterval = setInterval(() => {
                setOtpTimer((prevTimer) => prevTimer - 1);
                setSendOtpText(`Resend OTP (${otpTimer}s)`);
            }, 1000);

            return () => {
                clearInterval(timerInterval);
            };
        } else {
            setOtpTimer(reSendOtpIntervalInMs/1000); // Reset timer if OTP is not sent or timer reaches 0
            setOtpSent(false);
            setOtpButtonDisabled(false);
            handleOtpChange('');
            console.log(`otpsent ${otpSent} otpTimeer ${otpTimer}`)
            setSendOtpText(`Send OTP`);
        }
        
    }, [otpSent, otpTimer]); // Re-run effect when otpSent changes

    const handlePhoneNumberChange = (event) => {
        const { value } = event.target;
        // Add logic to validate phone number
        setPhoneNumber(value);
        if(value.length > 10 || !isPureNumber(value)){
            setPhoneNumberError(true);
            setPhoneNumberErrorText(getValidatedWhatsAppNumberMessages(value));  
        } else {
            setPhoneNumberError(false);
            setPhoneNumberErrorText("");
        }
       
    };

   

    const handleOtpChange = (value) => {
        setOtp(value);
        if (value.length === otpLength) {
            // Add logic to auto-submit OTP
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    };

    const handleSendOtp = async () => {
        // Add logic to send OTP
        otpSentTime = new Date().getTime();
        setOtpSent(true);
        setSendOtpText('RESEND OTP');
        setOtpButtonDisabled(true); // Disable send OTP button
        try {
            const otpResponse = await initiateOtp({
                "phone_number": phoneNumber,
                "isSignup": isSignup
            });
            trackEvent(eventCategories.userAccount, 'send_otp', 'otp sent sucessfully');
        } catch (error) {
            console.log(error);
            const errorData = error?.response?.data;
            setPhoneNumberError(true);
            setPhoneNumberErrorText(errorData?.message);  
        }
        
        ;
    };

    const handleSubmit = async () => {
        // Add logic to handle OTP verification and submission
        const otpResponse =  await verifyOtp({
            "phone_number": phoneNumber,
            "otp":otp
        });
        console.log(otpResponse);
        onSubmit({...otpResponse?.data, phone_number: phoneNumber});
        const response = otpResponse?.data;
        const endTime = new Date().getTime();
        const parameters = {
            elapsed_time: otpSentTime - endTime,
            isSuccess:  response?.isSuccess
        }
        trackEvent(eventCategories.userAccount, 'send_otp', 'otp received', parameters);
    };

    return (
        <Grid
            container
            justifyContent="center"
            sx={{ padding: '16px', maxWidth: '400px', margin: 'auto' }}
        >
            <Grid item xs={12}>
                <TextField
                    label="Whatsapp Number"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    fullWidth
                    placeholder='Enter whatsapp number'
                    error={phoneNumberError}
                    helperText={phoneNumberErrorText}
                    onBlur={onPhoneNumberBlur}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    onClick={handleSendOtp}
                    disabled={otpSent || otpButtonDisabled || phoneNumber.length !== 10}
                    fullWidth
                    variant="text"
                >
                     {sendOtpText}
                </Button>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="OTP"
                    value={otp}
                    onChange={(e) => handleOtpChange(e?.target?.value)}
                    disabled={!otpSent}
                    fullWidth
                    placeholder='Enter 5 Digit OTP'
                    error={otp.length > otpLength}
                    helperText={otp.length > otpLength ? `OTP number must be ${otpLength} digits"`: ""}
                />
            </Grid>
            <Grid item xs={12}>
                <Button onClick={handleSubmit} disabled={submitDisabled || propsDisableSubmit} fullWidth>
                    Submit
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" style={{marginTop:'0.8em'}}>
                    Note: Your OTP will be sent via WhatsApp. Kindly wait for up to a minute. Thank you for your patience
                </Typography>
            </Grid>
        </Grid>
    );
};

export default WhatsAppLogin;
